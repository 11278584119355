<template>
    <v-section-group>
        <v-section-group id="order-details">
            <v-order-details :order="order" />
        </v-section-group>

        <v-section-group v-if="$me.hasPermission('orders.meta.read')" id="order-meta-information">
            <v-section-heading>
                <template v-slot:default>
                    Order Meta Information
                </template>

                <template v-slot:description>
                    All the custom checkout field information.
                </template>
            </v-section-heading>

            <v-order-meta-information :data-function="getMetaInformation" :query="query" />
        </v-section-group>

        <v-section-group v-if="$me.hasPermission('order-fees.read')" id="order-fees">
            <v-section-heading>
                <template v-slot:default>
                    Order Fees
                </template>

                <template v-slot:description>
                    Additional costs that the order has aside from the jobs.
                </template>
            </v-section-heading>

            <v-order-fee-table :data-function="getOrderFees" :query="query" />
        </v-section-group>

        <v-section-group v-if="$me.hasPermission('order-lines.read')" id="order-lines">
            <v-section-heading>
                <template v-slot:default>
                    Jobs
                </template>

                <template v-slot:description>
                    All the jobs generated from the order created by the user.
                </template>
            </v-section-heading>

            <v-job-table :data-function="getJobs" :query="query" :show-toolbar="false" />
        </v-section-group>
    </v-section-group>
</template>

<script>
import OrderFeeTable from "@/components/application/order-fee/order-fee-table.vue";
import OrderDetails from "@/components/application/order/order-details.vue";
import OrderMetaInformation from "@/components/application/order/order-meta-information.vue";
import OrderService from "@/services/modules/order-service";
import JobTable from "@/components/application/job/job-table.vue";

export default {
    components: {
        "v-order-details": OrderDetails,
        "v-order-meta-information": OrderMetaInformation,
        "v-order-fee-table": OrderFeeTable,
        "v-job-table": JobTable,
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            query: {
                order_id: this.order.order_id,
            },
            getOrderFees: (payload) => OrderService.getOrderFees(this.order.order_id, payload),
            getJobs: (payload) => OrderService.getJobs(this.order.order_id, payload),
            getMetaInformation: (payload) => OrderService.getMetaInformation(this.order.order_id, payload),
        };
    },
};
</script>
