<template>
    <v-model-table v-bind="tableDataSource" module="orderModule" :columns="columns" :query="query" :show-toolbar="false">
        <template v-slot:column_amount="{ row }">
            <v-text>
                {{ row.amount | numberFormat }}
            </v-text>
        </template>
    </v-model-table>
</template>

<script>
export default {
    name: "OrderTable",
    props: {
        dataFunction: {
            type: Function,
            required: false,
            default: null,
        },
        query: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    computed: {
        /**
         * Returns the attributes that should be bound to the table.
         */
        tableDataSource() {
            if (this.dataFunction) {
                return {
                    dataFunction: this.dataFunction,
                };
            }

            if (this.service) {
                return {
                    service: this.service,
                };
            }

            return {
                module: "orderModule",
            };
        },
        columns() {
            const tableColumns = {
                order_fee_type_name: {
                    label: "Type",
                    fullWidth: true,
                    align: "left",
                },
                amount: {
                    label: "Amount",
                    fullWidth: false,
                    align: "right",
                },
            };

            return tableColumns;
        },
    },
    methods: {
        selectOrderLine(orderLineId) {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    jobId: orderLineId || undefined,
                    jobSlideOver: orderLineId ? true : undefined,
                },
            });
        },
    },
};
</script>
