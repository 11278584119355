<template>
    <v-model-table v-bind="tableDataSource" module="orderModule" :columns="columns" :query="query" />
</template>

<script>
export default {
    name: "OrderTable",
    props: {
        dataFunction: {
            type: Function,
            required: false,
            default: null,
        },
        query: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    computed: {
        /**
         * Returns the attributes that should be bound to the table.
         */
        tableDataSource() {
            if (this.dataFunction) {
                return {
                    dataFunction: this.dataFunction,
                };
            }

            if (this.service) {
                return {
                    service: this.service,
                };
            }

            return {
                module: "orderModule",
            };
        },
        columns() {
            const tableColumns = {
                display_key: {
                    label: "Label",
                    align: "left",
                    fullWidth: false,
                },
                value: {
                    label: "Value",
                    align: "left",
                    fullWidth: false,
                },
            };

            return tableColumns;
        },
    },
};
</script>
